import { Link } from 'react-router-dom'; 

const Play = () => {
  return (
    <div class='playnow-section'>
      <div class='container'>
        <div class='row'>
          <div class='col-12 position-relative'>
            <h2>Play Now</h2>
            <a
              class='view-all-link'
              href='#'
            >
              View all{" "}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                class='bi bi-arrow-right'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8'
                />
              </svg>
            </a>
          </div>
        </div>
        <div class='row'>
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <Link to={`/play-now/123`}>
                <figure>
                  <img
                    src='/game-thumb-img-01.jpg'
                    alt='Mighty Action Heroes'
                    title='Mighty Action Heroes'
                  />
                  <figcaption>
                    <h3>Mighty Action Heroes</h3>
                    <span class='category'>Battle Royale</span>
                    <span class='category'>Shooter</span>
                  </figcaption>
                </figure>
              </Link>
            </div>
          </div>
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <Link to={`/play-now/123`}>
                <figure>
                  <img
                    src='/game-thumb-img-01.jpg'
                    alt='Mighty Action Heroes'
                    title='Mighty Action Heroes'
                  />
                  <figcaption>
                    <h3>Mighty Action Heroes</h3>
                    <span class='category'>Battle Royale</span>
                    <span class='category'>Shooter</span>
                  </figcaption>
                </figure>
              </Link>
            </div>
          </div>
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <Link to={`/play-now/123`}>
                <figure>
                  <img
                    src='/game-thumb-img-01.jpg'
                    alt='Mighty Action Heroes'
                    title='Mighty Action Heroes'
                  />
                  <figcaption>
                    <h3>Mighty Action Heroes</h3>
                    <span class='category'>Battle Royale</span>
                    <span class='category'>Shooter</span>
                  </figcaption>
                </figure>
              </Link>
            </div>
          </div>
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <Link to={`/play-now/123`}>
                <figure>
                  <img
                    src='/game-thumb-img-01.jpg'
                    alt='Mighty Action Heroes'
                    title='Mighty Action Heroes'
                  />
                  <figcaption>
                    <h3>Mighty Action Heroes</h3>
                    <span class='category'>Battle Royale</span>
                    <span class='category'>Shooter</span>
                  </figcaption>
                </figure>
              </Link>
            </div>
          </div>
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <Link to={`/play-now/123`}>
                <figure>
                  <img
                    src='/game-thumb-img-01.jpg'
                    alt='Mighty Action Heroes'
                    title='Mighty Action Heroes'
                  />
                  <figcaption>
                    <h3>Mighty Action Heroes</h3>
                    <span class='category'>Battle Royale</span>
                    <span class='category'>Shooter</span>
                  </figcaption>
                </figure>
              </Link>
            </div>
          </div>
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <Link to={`/play-now/123`}>
                <figure>
                  <img
                    src='/game-thumb-img-01.jpg'
                    alt='Mighty Action Heroes'
                    title='Mighty Action Heroes'
                  />
                  <figcaption>
                    <h3>Mighty Action Heroes</h3>
                    <span class='category'>Battle Royale</span>
                    <span class='category'>Shooter</span>
                  </figcaption>
                </figure>
              </Link>
            </div>
          </div>
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <Link to={`/play-now/123`}>
                <figure>
                  <img
                    src='/game-thumb-img-01.jpg'
                    alt='Mighty Action Heroes'
                    title='Mighty Action Heroes'
                  />
                  <figcaption>
                    <h3>Mighty Action Heroes</h3>
                    <span class='category'>Battle Royale</span>
                    <span class='category'>Shooter</span>
                  </figcaption>
                </figure>
              </Link>
            </div>
          </div>
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <Link to={`/play-now/123`}>
                <figure>
                  <img
                    src='/game-thumb-img-01.jpg'
                    alt='Mighty Action Heroes'
                    title='Mighty Action Heroes'
                  />
                  <figcaption>
                    <h3>Mighty Action Heroes</h3>
                    <span class='category'>Battle Royale</span>
                    <span class='category'>Shooter</span>
                  </figcaption>
                </figure>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Play;

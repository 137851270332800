import Nav from "../components/nav";
import TickerBar from "../components/ticker";
import StartJourney from "../components/startJourney";
import Footer from "../components/footer";
import PlayNow from "../components/playNow";

const PlayNowDetail = () => {
    return (
        <main>
            <Nav />
            <TickerBar />
            <PlayNow />
            <StartJourney />
            <Footer />
        </main>
    );
};

export default PlayNowDetail;
import axiosDefault from 'axios'

export const adminAxios = axiosDefault.create({
  baseURL: process.env.REACT_APP_ADMIN_BE+'/api',
  headers:{
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`
  }
})


export const mainAxios = axiosDefault.create({
  baseURL: process.env.REACT_APP_MAIN_BE,
})

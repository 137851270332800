const Listing = () => {
  return (
    <div class='listing-section'>
      <div class='container'>
        <div class='row'>
          <div class='col-lg-4 col-md-6 col-sm-12'>
            <div class="flex items-center">
              <img src='/epic_games_logo_icon_145306.png' alt='Logo' class='mr-2.5 w-12 h-auto mb-2.5' />
              <h2>LEADERBOARD</h2>
            </div>
            <div class='card-listing scroll-y'>
              <ul class='Leaderboard'>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>1</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>2</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>3</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>4</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>5</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>6</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>7</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>8</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class='col-lg-4 col-md-6 col-sm-12'>
            <div class="flex items-center">
              <img src='/thepark-logo.png' alt='Logo' class='mr-2.5 w-12 h-[48px;] mb-2.5' />
              <h2>LEADERBOARD</h2>
            </div>
            <div class='card-listing scroll-y'>
              <ul class='Leaderboard'>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>1</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>2</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>3</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>4</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>5</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>6</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>7</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      <span class='list-no'>8</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class='col-lg-4 col-md-6 col-sm-12'>
            <h2>STREAMING</h2>
            <div class='card-listing scroll-y'>
              <ul>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      754
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        class='bi bi-eye'
                        viewBox='0 0 16 16'
                      >
                        <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
                        <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
                      </svg>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      754
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        class='bi bi-eye'
                        viewBox='0 0 16 16'
                      >
                        <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
                        <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
                      </svg>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      754
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        class='bi bi-eye'
                        viewBox='0 0 16 16'
                      >
                        <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
                        <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
                      </svg>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      754
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        class='bi bi-eye'
                        viewBox='0 0 16 16'
                      >
                        <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
                        <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
                      </svg>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      754
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        class='bi bi-eye'
                        viewBox='0 0 16 16'
                      >
                        <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
                        <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
                      </svg>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      754
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        class='bi bi-eye'
                        viewBox='0 0 16 16'
                      >
                        <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
                        <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
                      </svg>
                    </div>
                  </div>
                </li>
                <li>
                  <div class='row'>
                    <div class='col-auto pr-0'>
                      <img
                        src='/list-img-001.jpg'
                        alt=''
                      />
                    </div>
                    <div class='col'>
                      <h3>BotlightM</h3>
                      <span>170XP</span>
                    </div>
                    <div class='col-auto pl-0 mt-auto mb-auto'>
                      754
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        class='bi bi-eye'
                        viewBox='0 0 16 16'
                      >
                        <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
                        <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
                      </svg>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listing;

const StartJourney = () => {
  return (
    <div class='start-journey'>
      <div class='container'>
        <div class='row'>
          <div class='col-12'>
            <h2>Ready to start your journey?</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived
            </p>
            <button class='btn btn-primary'>Join Now</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartJourney;

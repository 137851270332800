import { useEffect, useState } from "react";
import DiscoverGame from "../components/discoverGame";
import Editor from "../components/editor";
import Footer from "../components/footer";
import Header from "../components/header";
import LatestQuests from "../components/latestQuests";
import Listing from "../components/listing";
import Nav from "../components/nav";
import Play from "../components/play";
import Reviews from "../components/reviews";
import StartJourney from "../components/startJourney";
import TickerBar from "../components/ticker";
import { apiGetGamess, apiGetReviews } from "../apis";

const Home = () => {
  const [ reviews, setReviews ] = useState([])
  const [ games, setGames ] = useState([])
  const getReviews = async () => {
    const data = await apiGetReviews()
    if(data?.length > 0) setReviews(data)
  }
  const getGames = async () => {
    const data = await apiGetGamess()
    if(data?.length > 0) setGames(data)
  }

  useEffect(()=>{
    getReviews()
    getGames()
    const interval = setInterval(()=>{
      getReviews()
      getGames()
    },[5000] )

    return clearInterval(interval)
  },[])
  return (
    <>
      {/* {isLoggedIn && (
        <div className='container-fluid d-flex align-items-center justify-content-center vh-100'>
          <div className='col-md-4'>
            <form>
              <div className='mb-3'>
                <label
                  htmlFor='password'
                  className='form-label'
                >
                  Password
                </label>
                <input
                  type='password'
                  className='form-control text-black'
                  id='password'
                  placeholder='Enter your password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button
                type='submit'
                className='btn btn-primary'
                onClick={handleLogin}
              >
                Login
              </button>
            </form>
          </div>
        </div>
      )} */}
      {/* {!isLoggedIn && ( */}
      <main>
        <Nav />
        <TickerBar/>
        <Header games={games} />
        <Listing />
        <Play />
        <LatestQuests />
        <Reviews reviews={reviews}/>
        <Editor />
        <DiscoverGame />
        <StartJourney />
        <Footer />
        
      </main>
      {/* )} */}
    </>
  );
};

export default Home;

const Reviews = ( {reviews} ) => {
  console.log('reviews in Newest Reviews', reviews)
  return (
    <div class='newest-reviews'>
      <div class='container'>
        <div class='row'>
          <div class='col-12 position-relative'>
            <h2 class='text-start'>Newest Reviews</h2>
            <a
              class='view-all-link'
              href='#'
            >
              View all{" "}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                class='bi bi-arrow-right'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8'
                />
              </svg>
            </a>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived
            </p>
          </div>
        </div>
        <div class='row'>
          {
            reviews?.map(({attributes, id})=>(
              <div key={id} class='col-lg-3 col-md-6 col-6 col-xsm-w'>
                <div class='card-game'>
                  <figure>
                    <img
                      src='/game-002.jpg'
                      alt='The King Of Fighters'
                      title='The King Of Fighters'
                    />
                    <figcaption class='position-relative'>
                      <div class='review-v'>{attributes?.score}</div>
                      <h3>{attributes?.Title}</h3>
                      <p class='m-0'>{attributes?.Description}</p>
                    </figcaption>
                  </figure>
                </div>
              </div>
            ))
          }
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <figure>
                <img
                  src='/game-002.jpg'
                  alt='The King Of Fighters'
                  title='The King Of Fighters'
                />
                <figcaption class='position-relative'>
                  <div class='review-v'>7.8</div>
                  <h3>The King Of Fighters</h3>
                  <p class='m-0'>Lorem Ipsum is simply dummy text of the</p>
                </figcaption>
              </figure>
            </div>
          </div>
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <figure>
                <img
                  src='/game-002.jpg'
                  alt='The King Of Fighters'
                  title='The King Of Fighters'
                />
                <figcaption class='position-relative'>
                  <div class='review-v'>7.8</div>
                  <h3>The King Of Fighters</h3>
                  <p class='m-0'>Lorem Ipsum is simply dummy text of the</p>
                </figcaption>
              </figure>
            </div>
          </div>
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <figure>
                <img
                  src='/game-002.jpg'
                  alt='The King Of Fighters'
                  title='The King Of Fighters'
                />
                <figcaption class='position-relative'>
                  <div class='review-v'>7.8</div>
                  <h3>The King Of Fighters</h3>
                  <p class='m-0'>Lorem Ipsum is simply dummy text of the</p>
                </figcaption>
              </figure>
            </div>
          </div>
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <figure>
                <img
                  src='/game-002.jpg'
                  alt='The King Of Fighters'
                  title='The King Of Fighters'
                />
                <figcaption class='position-relative'>
                  <div class='review-v'>7.8</div>
                  <h3>The King Of Fighters</h3>
                  <p class='m-0'>Lorem Ipsum is simply dummy text of the</p>
                </figcaption>
              </figure>
            </div>
          </div>
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <figure>
                <img
                  src='/game-002.jpg'
                  alt='The King Of Fighters'
                  title='The King Of Fighters'
                />
                <figcaption class='position-relative'>
                  <div class='review-v'>7.8</div>
                  <h3>The King Of Fighters</h3>
                  <p class='m-0'>Lorem Ipsum is simply dummy text of the</p>
                </figcaption>
              </figure>
            </div>
          </div>
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <figure>
                <img
                  src='/game-002.jpg'
                  alt='The King Of Fighters'
                  title='The King Of Fighters'
                />
                <figcaption class='position-relative'>
                  <div class='review-v'>7.8</div>
                  <h3>The King Of Fighters</h3>
                  <p class='m-0'>Lorem Ipsum is simply dummy text of the</p>
                </figcaption>
              </figure>
            </div>
          </div>
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <figure>
                <img
                  src='/game-002.jpg'
                  alt='The King Of Fighters'
                  title='The King Of Fighters'
                />
                <figcaption class='position-relative'>
                  <div class='review-v'>7.8</div>
                  <h3>The King Of Fighters</h3>
                  <p class='m-0'>Lorem Ipsum is simply dummy text of the</p>
                </figcaption>
              </figure>
            </div>
          </div>
          <div class='col-lg-3 col-md-6 col-6 col-xsm-w'>
            <div class='card-game'>
              <figure>
                <img
                  src='/game-002.jpg'
                  alt='The King Of Fighters'
                  title='The King Of Fighters'
                />
                <figcaption class='position-relative'>
                  <div class='review-v'>7.8</div>
                  <h3>The King Of Fighters</h3>
                  <p class='m-0'>Lorem Ipsum is simply dummy text of the</p>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;

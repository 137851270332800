import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiGetGame } from "../apis";

const GameBlog = () => {
    const { gameId } = useParams();
    const [game, setGame] = useState(null);

    const getGame = async () => {
        const data = await apiGetGame(gameId);
        if (data) {
            setGame(data);
        }
    };

    useEffect(() => {
        getGame();
        const interval = setInterval(getGame, 5000);
        return () => clearInterval(interval);
    }, [gameId]);

    return (
        <div className='container mx-auto'>
            <div className='row flex'>
                {game ? (
                    <div className="game-details flex w-full">
                        <div className="cover-image w-1/2 p-4">
                            <img src={`${game.attributes.Cover.data.attributes.url}`} alt={game.attributes.Title} className="w-full rounded-lg object-cover" />
                        </div>
                        <div className="game-info w-1/2 p-4 flex flex-col justify-center bg-[#19181e]">
                            <h1 className="font-bold text-4xl text-white mb-4">{game.attributes.Title}</h1>
                            <p className="text-lg text-gray-300 mb-4">{game.attributes.Description}</p>
                            <a href={game.attributes.Url} target="_blank" rel="noopener noreferrer" className="btn btn-primary bg-blue-500 text-white py-2 px-4 rounded cursor-pointer no-underline hover:bg-blue-700">Visit Game</a>
                        </div>
                    </div>
                ) : (
                    <p>Loading game details...</p>
                )}
            </div>
        </div>
    );
};

export default GameBlog;

import { useEffect, useState } from "react"
import { tickersData } from "../apis"
import { FaArrowDown, FaArrowUp  } from "react-icons/fa";


export default function TickerBar(){
  const [tickers, setTickers] = useState([])
  const getTickerBarData = async () => {
    const data = await tickersData()
    setTickers(data)
  }
  useEffect(()=>{
    getTickerBarData()
  },[])
  return(
    <div className=" px-2 pt-6 pb-2 flex items-center bg-black">
      <p className=" text-nowrap text-[#89fe00]">GAMING TOKEN ECOSYSTEMS:&nbsp;&nbsp;&nbsp;</p>
      <marquee >
        <div className="flex w-max space-x-4 items-center">
          {
            tickers.map(({logo, name, symbol, price, change, rank})=>(
              <TickerItem key={rank} logo={logo} name={name} symbol={symbol} price={price} change={change}/>
            ))
          }
        </div>
      </marquee>
    </div>
    
  )
}

export function TickerItem({logo, name, symbol, price, change, rank}) {
  
  return(
    <div className="flex space-x-4 items-center">
      <img src={logo} className=" max-h-[24px]"/>
      <span>{name} ({symbol})</span>
      <span>$ {price?.toFixed(2)}</span>
      <div className="flex space-x-1 items-center">
        {
          change > 0 ? <FaArrowUp className="text-green-600"/>:<FaArrowDown className="text-red-600"/>
        }
        <span className={`${change>0 ?' text-green-600':' text-red-600'}`}>{change?.toFixed(2)}%</span>

      </div>
    </div>
  )

}
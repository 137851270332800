import {adminAxios as axios, mainAxios} from "./axios"

import originAxios from "axios"



export const apiGetReviews = async () => {
  try{
    const { data } = await axios.get('/reviews')
    if( data ){
      return data.data  
    }else{
      return []
    }
  }catch(err){
    console.log('err in api getting reviews', err)
    return []
  }
}

export const apiGetGamess = async () => {
  try{
    const { data } = await axios.get('games?populate=*')
    console.log('games', data.data)
    if( data ){
      return data.data  
    }else{
      return []
    }
  }catch(err){
    console.log('err in api getting games', err)
    return []
  }
}

export const tickersData = async() => {
  const {data} =  await mainAxios.get('/query/tickers')
  if(data.success){
    return data.data
  }else{
    return []
  }
}

export const apiGetGame = async (id) => {
  try{
    const { data } = await axios.get(`games/${id}?populate=*`)
    console.log('game', data.data)
    if( data ){
      return data.data  
    }else{
      return []
    }
  }catch(err){
    console.log('err in api getting games', err)
    return []
  }
}
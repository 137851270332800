import { Link } from 'react-router-dom'; 

const DiscoverGame = () => {
  const gametype = ["ADVENTURE", "ARCADE", "AUTO BATTLE", "BATTLE ROYALE", "CARD", "CASUAL", "FIGHTING", "GAMBLING", "HORROR", "MOBA", "MMORPG", "MOBILE", "MULTIPLAYER", "MYSTERY", 
                   "ON CHAIN", "OPEN WORLD", "PLATFORMER", "PUZZLE", "RACING", "RPG", "SHOOTER", "SIMULATION", "SPORTS", "STRATEGY"]

  return (
    <div class='discover-games'>
      <div class='container'>
        <div class='row'>
          <div class='col-12'>
            <h2>Discover More Games</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry'
            </p>
          </div>
        </div>
        <div class="row">
          { gametype.map((type, index) => (
              <div key={index} className='col-lg-3 col-md-6 col-6 col-xsm-w'>
                <div className='Discover-card'>
                  <Link to={`/game-type/${type.toLowerCase()}`}>
                    <figure className='m-0'>
                      <img
                        src='/game-001.jpg'
                        alt=''
                      />
                      <figcaption>{type}</figcaption>
                    </figure>
                  </Link>
                </div>
              </div>
            )) }
        </div>
      </div>
    </div>
  );
};

export default DiscoverGame;

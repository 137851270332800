import Nav from "../components/nav";
import TickerBar from "../components/ticker";
import GameType from "../components/gameType";
import StartJourney from "../components/startJourney";
import Footer from "../components/footer";

const ViewGamesByType = () => {
    return (
        <main>
            <Nav />
            <TickerBar />
            <GameType />
            <StartJourney />
            <Footer />
        </main>
    );
};

export default ViewGamesByType;

const LatestQuests = () => {
  return (
    <div class='latest-quests sr-only'>
      <div class='container'>
        <div class='row'>
          <div class='col-12 position-relative'>
            <h2>Latest Quests</h2>
            <a
              class='view-all-link'
              href='#'
            >
              View all{" "}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                class='bi bi-arrow-right'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8'
                />
              </svg>
            </a>
          </div>
        </div>
        <div class='row'>
          <div class='col-lg-6 col-md-6 col-6 col-xsm-w'>
            <div class='lq-card-game'>
              <div class='lq-card-label'>LIVE</div>
              <figure>
                <img
                  src='/game-thumb-img-01.jpg'
                  alt='Galactic Veterans'
                  title='Galactic Veterans'
                />
                <figcaption>
                  <h3>Galactic Veterans</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <div class='row'>
                    <div class='col'>
                      <div class='Competing-val'>1745 Competing</div>
                    </div>
                    <div class='col missions-no'>
                      Missions: <span class='m-no'>0</span> / 5
                    </div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
          <div class='col-lg-6 col-md-6 col-6 col-xsm-w'>
            <div class='lq-card-game'>
              <div class='lq-card-label'>LIVE</div>
              <figure>
                <img
                  src='/game-thumb-img-01.jpg'
                  alt='Galactic Veterans'
                  title='Galactic Veterans'
                />
                <figcaption>
                  <h3>Galactic Veterans</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <div class='row'>
                    <div class='col'>
                      <div class='Competing-val'>1745 Competing</div>
                    </div>
                    <div class='col missions-no'>
                      Missions: <span class='m-no'>0</span> / 5
                    </div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
          <div class='col-lg-6 col-md-6 col-6 col-xsm-w'>
            <div class='lq-card-game'>
              <div class='lq-card-label'>LIVE</div>
              <figure>
                <img
                  src='/game-thumb-img-01.jpg'
                  alt='Galactic Veterans'
                  title='Galactic Veterans'
                />
                <figcaption>
                  <h3>Galactic Veterans</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <div class='row'>
                    <div class='col'>
                      <div class='Competing-val'>1745 Competing</div>
                    </div>
                    <div class='col missions-no'>
                      Missions: <span class='m-no'>0</span> / 5
                    </div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
          <div class='col-lg-6 col-md-6 col-6 col-xsm-w'>
            <div class='lq-card-game'>
              <div class='lq-card-label'>LIVE</div>
              <figure>
                <img
                  src='/game-thumb-img-01.jpg'
                  alt='Galactic Veterans'
                  title='Galactic Veterans'
                />
                <figcaption>
                  <h3>Galactic Veterans</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <div class='row'>
                    <div class='col'>
                      <div class='Competing-val'>1745 Competing</div>
                    </div>
                    <div class='col missions-no'>
                      Missions: <span class='m-no'>0</span> / 5
                    </div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestQuests;

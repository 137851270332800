import { useParams } from "react-router-dom";


const EditorChoice = () => {
    const { editorChoice } = useParams();
    return (
        <div className='container mx-auto'>
            <div className='row flex'>
                <div className="game-details flex w-full">
                    <div className="cover-image w-1/2 p-4">
                        <img src="/game-thumb-img-01.jpg" alt="" className="w-full rounded-lg object-cover" />
                    </div>
                    <div className="game-info w-1/2 p-4 flex flex-col justify-center bg-[#19181e]">
                        <h1 className="font-bold text-4xl text-white mb-4">Mighty Action Heroes</h1>
                        <p className="text-lg text-gray-300 mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived</p>
                        <a href="" target="_blank" rel="noopener noreferrer" className="btn btn-primary bg-blue-500 text-white py-2 px-4 rounded cursor-pointer no-underline hover:bg-blue-700">Visit Game</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditorChoice;
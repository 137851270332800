import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/home";
import ViewGame from "./pages/viewGame";
import ViewGamesByType from "./pages/viewGamesByType";
import EditorChoiceDetail from "./pages/editorChoiceDetail";
import PlayNowDetail from "./pages/playNowDetail";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/7bG8k3nH2oP1qR' element={<Home />} />
        <Route path='/game-blogs/:gameId' element={<ViewGame />} />
        <Route path='/game-type/:gameType' element={<ViewGamesByType />} />
        <Route path='/editor-choice/:editorChoice' element={<EditorChoiceDetail />} />
        <Route path='/play-now/:playNow' element={<PlayNowDetail />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import { useNavigate } from "react-router-dom";  // Import useNavigate

const Header = ({ games }) => {
  const navigate = useNavigate();  // Initialize the navigate function

  const handleViewGame = (gameId) => {
    navigate(`/game-blogs/${gameId}`);  // Use navigate to go to the gameblog page
  };

  return (
    <div className='slider-section'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 col-md-12 mt-auto mb-auto'>
            <div className='slider-content'>
              <h1>
                <span>A NEW GAMING CONTENT PARADIGM</span> THE PARK
              </h1>
              <p>
                The Park is dedicated to bringing quality content to the broader gaming community.  
                Focusing on both classic gaming as well as delving into the growing "web3" gaming industry, The Park was built by gamers, for gamers.  
                Participate in leaderboard challenges, complete detailed tasklists, watch top streamers, or indulge in the latest gaming reviews.
              </p>
              <button className='btn btn-primary'>CONNECT NOW</button>
            </div>
          </div>
          <div className='col-lg-6 col-md-12 mt-auto mb-auto'>
            <div
              id='carouselGame'
              className='carousel slide'
            >
              <div className='carousel-indicators'>
                {
                  games && games.map((_, index) => (
                    <button
                      type='button'
                      data-bs-target='#carouselGame'
                      data-bs-slide-to={index}
                      className={index === 0 ? 'active' : ''}
                      aria-current='true'
                      aria-label={`Slide ${index}`}
                    ></button>
                  ))
                }
              </div>
              
              <div className='carousel-inner'>
                {
                  games && games.map((game, index) => (
                    <div
                      className={`carousel-item ${index === 0 ? 'active' : ''}`}
                      data-bs-interval=''
                    >
                      <figure className='slider-card-game'>
                        <img
                          src={`${game.attributes.Cover.data.attributes.url}`}
                          alt={game.attributes.Title}
                          title={game.attributes.Title}
                        />
                        <figcaption>
                          <h2>{game.attributes.Title}</h2>
                          <p>
                            {game.attributes.Description}
                          </p>
                          <br/>
                          {/* Changed to button and added onClick handler */}
                          <button 
                            className='btn btn-primary me-2' 
                            onClick={() => handleViewGame(game.id)}
                          >
                            View Game
                          </button>
                        </figcaption>
                      </figure>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

const Nav = () => {
  return (
    <header className="w-full fixed z-50 py-[10px] transition-all duration-500 ease-in-out top-0 left-0 bg-[#19181ecc] backdrop-blur">
      <nav class='navbar navbar-expand-lg'>
        <div class='container-fluid'>
          <a
            class='navbar-brand my-4'
            href='#'
          >
            <img
              src='/thepark-logo.png'
              alt='ThePark'
              title='ThePark'
            />
          </a>

          <button
            class='navbar-toggler my-4'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNavDropdown'
            aria-controls='navbarNavDropdown'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <div class='icon-container'>
              <div id='menuicon'>
                <div class='bar bar1'></div>
                <div class='bar bar2'></div>
                <div class='bar bar3'></div>
              </div>
            </div>
          </button>

          <div
            className="flex"
            class='navbar-collapse'
            id='navbarNavDropdown'
          >
            <ul class='navbar-nav'>
              <li class='nav-item'>
                <a
                  class='nav-link active'
                  href='#'
                >
                  HOME 
                </a>
              </li>
              <li class='nav-item'>
                <a
                  class='nav-link'
                  href='#'
                >
                  GAMES 
                </a>
              </li>
              <li class='nav-item'>
                <a
                  class='nav-link'
                  href='#'
                >
                  QUESTS 
                </a>
              </li>
              <li class='nav-item'>
                <a
                  class='nav-link'
                  href='#'
                >
                  LEADERBOARDS 
                </a>
              </li>
              <li class='nav-item'>
                <a
                  class='nav-link'
                  href='#'
                >
                  WEB3 
                </a>
              </li>
              <li class='nav-item'>
                <a
                  class='nav-link'
                  href='#'
                >
                  CONTACT
                </a>
              </li>
              {/*  <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="dropdownlinks" role="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Dropdown links
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="dropdownlinks">
                                <li><a class="dropdown-item" href="#">Dropdown links1</a></li>
                                <li><a class="dropdown-item" href="#">Dropdown links2</a></li>
                                <li><a class="dropdown-item" href="#">Dropdown links3</a></li>
                            </ul>
                        </li> */}
              <li class='nav-item login'>
                <a
                  class='nav-link'
                  href='#'
                >
                  CONNECT
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Nav;

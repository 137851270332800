import Nav from "../components/nav";
import TickerBar from "../components/ticker";
import EditorChoice from "../components/editorChoice";
import StartJourney from "../components/startJourney";
import Footer from "../components/footer";

const EditorChoiceDetail = () => {
    return (
        <main>
            <Nav />
            <TickerBar />
            <EditorChoice />
            <StartJourney />
            <Footer />
        </main>
    );
};

export default EditorChoiceDetail;
import { useParams } from "react-router-dom";


const GameType = () => {
    const { gameType } = useParams();
    return (
        <>
        <div className="mx-auto py-5 w-4/5">
            <div class="p-10 row">
                <div class='col-lg-4 col-md-6 col-6 col-xsm-w'>
                    <div class='card-game'>
                        <div class='ec-no'>#1</div>
                        <figure>
                            <img
                                src='/game-thumb-img-01.jpg'
                                alt='Mighty Action Heroes'
                                title='Mighty Action Heroes'
                            />
                            <figcaption>
                                <h3>{gameType} game is here...</h3>
                                <button class='btn btn-primary'>PLAY NOW</button>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <div class='col-lg-4 col-md-6 col-6 col-xsm-w'>
                    <div class='card-game'>
                        <div class='ec-no'>#2</div>
                        <figure>
                            <img
                                src='/game-thumb-img-01.jpg'
                                alt='Mighty Action Heroes'
                                title='Mighty Action Heroes'
                            />
                            <figcaption>
                                <h3>{gameType} game is here...</h3>
                                <button class='btn btn-primary'>PLAY NOW</button>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <div class='col-lg-4 col-md-6 col-6 col-xsm-w'>
                    <div class='card-game'>
                        <div class='ec-no'>#3</div>
                        <figure>
                            <img
                                src='/game-thumb-img-01.jpg'
                                alt='Mighty Action Heroes'
                                title='Mighty Action Heroes'
                            />
                            <figcaption>
                                <h3>{gameType} game is here...</h3>
                                <button class='btn btn-primary'>PLAY NOW</button>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <div class='col-lg-4 col-md-6 col-6 col-xsm-w'>
                    <div class='card-game'>
                        <div class='ec-no'>#4</div>
                        <figure>
                            <img
                                src='/game-thumb-img-01.jpg'
                                alt='Mighty Action Heroes'
                                title='Mighty Action Heroes'
                            />
                            <figcaption>
                                <h3>{gameType} game is here...</h3>
                                <button class='btn btn-primary'>PLAY NOW</button>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <div class='col-lg-4 col-md-6 col-6 col-xsm-w'>
                    <div class='card-game'>
                        <div class='ec-no'>#5</div>
                        <figure>
                            <img
                                src='/game-thumb-img-01.jpg'
                                alt='Mighty Action Heroes'
                                title='Mighty Action Heroes'
                            />
                            <figcaption>
                                <h3>{gameType} game is here...</h3>
                                <button class='btn btn-primary'>PLAY NOW</button>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <div class='col-lg-4 col-md-6 col-6 col-xsm-w'>
                    <div class='card-game'>
                        <div class='ec-no'>#6</div>
                        <figure>
                            <img
                                src='/game-thumb-img-01.jpg'
                                alt='Mighty Action Heroes'
                                title='Mighty Action Heroes'
                            />
                            <figcaption>
                                <h3>{gameType} game is here...</h3>
                                <button class='btn btn-primary'>PLAY NOW</button>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <div class='col-lg-4 col-md-6 col-6 col-xsm-w'>
                    <div class='card-game'>
                        <div class='ec-no'>#7</div>
                        <figure>
                            <img
                                src='/game-thumb-img-01.jpg'
                                alt='Mighty Action Heroes'
                                title='Mighty Action Heroes'
                            />
                            <figcaption>
                                <h3>{gameType} game is here...</h3>
                                <button class='btn btn-primary'>PLAY NOW</button>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <div class='col-lg-4 col-md-6 col-6 col-xsm-w'>
                    <div class='card-game'>
                        <div class='ec-no'>#8</div>
                        <figure>
                            <img
                                src='/game-thumb-img-01.jpg'
                                alt='Mighty Action Heroes'
                                title='Mighty Action Heroes'
                            />
                            <figcaption>
                                <h3>{gameType} game is here...</h3>
                                <button class='btn btn-primary'>PLAY NOW</button>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <div class='col-lg-4 col-md-6 col-6 col-xsm-w'>
                    <div class='card-game'>
                        <div class='ec-no'>#9</div>
                        <figure>
                            <img
                                src='/game-thumb-img-01.jpg'
                                alt='Mighty Action Heroes'
                                title='Mighty Action Heroes'
                            />
                            <figcaption>
                                <h3>{gameType} game is here...</h3>
                                <button class='btn btn-primary'>PLAY NOW</button>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default GameType;
import Nav from "../components/nav";
import TickerBar from "../components/ticker";
import GameBlog from "../components/gameBlog";
import StartJourney from "../components/startJourney";
import Footer from "../components/footer";

const ViewGame = () => {
    return (
        <main>
            <Nav />
            <TickerBar />
            <GameBlog />
            <StartJourney />
            <Footer />
        </main>
    );
};

export default ViewGame;
